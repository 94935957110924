@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');

body {
    font-family: 'Roboto', sans-serif;
}

.icw-admin-main-container .ui.segment {
    border: none;
    box-shadow: none;
    background: transparent;
    margin: 0px;
    padding: 10px 0px;
}


.main-logo-wrapper img {
    max-height: 35px;
}

.icw-admin-main-container .ui.grid > .row {
    padding: 0px 20px 0px;
}

.icw-admin-main-container .ui.grid {
    margin: 0px;
}


.icw-admin-main-container .ui.segment.right.aligned {
    padding-bottom: 0px;
}

.header-mail-wrapper a {
    cursor: pointer;
    color: #fff;
}

.footer-main-container {
    position: fixed;
    width: 100%;
    bottom: 0px;
    background: #EDEDED 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #55555580;
    opacity: 1;
    padding: 5px 0px;
}

.header-mail-wrapper {
    padding-top: 11px;
}

a.email-us-tag {
    cursor: pointer;
}

.all-right-resevered-wrapper, .footer-need-help-wrapper {
    font-size: 12px;
}


.icw-common-box-wrapper {
    width: 400px;
    left: 50%;
    margin: 160px auto auto -205px !important;
    position: absolute;
}

.icw-common-body-wrapper {
    position: relative;
}

.icw-common-box-wrapper .ui.segment {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 14px 16px #0000002B;
    border: 1px solid #D8D8D8;
    border-radius: 5px;
    opacity: 1;
    padding: 30px;
}

.kit-wrapper .ui.form .field > label {
    float: left !important;
    width: auto;
    color: #595959;
    font-weight: 400 !important;
}

.kit-wrapper input, .kit-wrapper textarea, .kit-wrapper select, .kit-wrapper .selection.dropdown, .kit-wrapper .selection.dropdown .item, .kit-wrapper label, .kit-wrapper p {
    width: 100%;
    box-sizing: border-box;
    font-size: 13px !important;
    min-width: inherit;
    font-weight: 400;
}

.kit-wrapper .ui.checkbox input {
    display: inline !important;
    width: auto !important;
    position: relative;
    top: 2px;
    margin-left: 0px;
}

.kit-wrapper .ui.checkbox label {
    display: inline;
}

.kit-wrapper .align-center {
    text-align: center;
}

.kit-wrapper .hint-text {
    color: #000;
    margin: 0px !important;
}

.kit-wrapper .ui.search.dropdown > input.search {
    padding-left: 10px !important;
    padding-top: 7px !important;
    line-height: 15px !important;
}

.kit-wrapper .ui.multiple.search.dropdown input.search {
    padding-left: 5px !important;
    padding-top: 0px !important;
    margin-left: 0px !important;
    margin-bottom: 0px;
}

.kit-wrapper .message {
    font-size: 12px;
}

.kit-wrapper button {
    padding: 7px 20px !important;
    min-width: 80px !important;
    font-size: 13px !important;
}

.kit-wrapper a.ui.label.transition.visible {
    background: #485865 none !important;
    color: rgb(255 255 255) !important;
    font-weight: 400;
    border-radius: .28571429rem;
    text-transform: none;
    text-shadow: none !important;
    -webkit-box-shadow: 0 0 0 1px rgba(34, 36, 38, .15) inset;
    box-shadow: none;
    border: none !important;
}

.kit-wrapper textarea {
    resize: unset !important;
    width: 300px !important;
    border: 1px solid #333333 !important;
    border-radius: 0px !important;
}

.kit-wrapper .ruled-ui .sibling-item {
    padding: 10px 15px 10px 10px;
    /* background: #efefef; */
    border: 1px solid #cccccc;
    border-radius: 0px;
    border-top: 0px solid !important;
    border-right: 0px !important;
    padding-right: 10px !important;
    border-bottom: 0px !important;
    margin-left: 0px !important;
    margin-top: 0px !important;
    box-shadow: inset 0px 2px 6px 1px rgb(0 0 0 / 7%);
}

.kit-wrapper .ruled-ui .sibling-item .sibling-item {
    border: 1px solid #ccc;
    margin-top: 10px;
    margin-left: 20px;
    margin-bottom: 3px;
    position: relative;
}

.kit-wrapper .ruled-ui > .sibling-item-wrapper > .sibling-item {
    padding-right: 10px !important;
}

.kit-wrapper .sibling-item i.trash.alternate.icon {
    position: absolute;
    right: 10px;
    font-size: 10px;
}

.kit-wrapper .child-item + .child-item {
    margin-top: 16px !important;
    padding-top: 11px !important;
    border-top: 1px solid #e0e0e0 !important;
}

.kit-wrapper .margin-top-20 {
    margin-top: 20px;
}

.kit-wrapper .ruled-ui .align-left + .sibling-item {
    margin-top: 14px !important;
}

.kit-wrapper .ruled-ui .child-item + .sibling-item {
    margin-top: 14px !important;
}

.kit-wrapper .child-item.ruled-ui {
    margin-top: 20px;
}

.kit-wrapper .child-item + .align-left {
    margin-top: 10px !important;
}


.kit-wrapper .ruled-ui .align-left + .sibling-item {
    margin-top: 14px !important;
}

.kit-wrapper .ui.form .error.message, .kit-wrapper .ui.form .success.message, .kit-wrapper .ui.form .warning.message {
    display: block;
}

.kit-wrapper .ui.checkbox input.hidden {
    display: none !important;
}

.kit-wrapper .ui.primary.button, .kit-wrapper .ui.primary.buttons .button {
    background: #007EB0 !important;
    font-size: 13px;
    font-weight: 400;
}


.kit-wrapper .ui.light.button {
    padding: 7px 5px !important;
    font-size: 11px !important;
    min-width: inherit !important;
    width: 100% !important;
    font-weight: 200;
    background: #dde6f5 !important;
    color: #000;
}

.kit-wrapper .simple-cirle {
    border: 1px solid;
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 10px;
}

.kit-wrapper .condition .line-verticle {
    border-left: 1px solid;
    height: 100px;
    display: none;
}

.kit-wrapper .condition .line-row {
    display: inline-block;
    border-bottom: 1px solid #e2e2e2;
    position: absolute;
    width: calc(100% - 80px);
    left: 30px;
    top: 10px;
}

.kit-wrapper .condition-header h5 {
    display: inline-block;
    background: #fff;
    position: relative;
    z-index: 1;
    padding-right: 8px;
}

.kit-wrapper .info-icon {
    background: #dedcdc;
    width: 19px;
    display: inline-block;
    text-align: center;
    border-radius: 20px;
    float: right;
}

.kit-wrapper .close-icon {
    background: #dedcdc;
    width: 18px;
    display: inline-block;
    text-align: center;
    border-radius: 20px;
    line-height: 16px;
    height: 19px;
    position: relative;
    top: 0px;
    float: right;
    margin-left: 5px;
}

.kit-wrapper .condition.nested {
    position: relative;
    padding-left: 19px;
    padding-bottom: 13px;
}

.kit-wrapper .condition.nested .simple-cirle {
    position: absolute;
    left: 0px;
    top: 7px;
    display: block !important;
}

.kit-wrapper .condition.nested .line-verticle {
    position: absolute;
    left: 5px;
    top: 16px;
    height: calc(100% - 15px);
    display: block;
}

.kit-wrapper .simple-cirle {
    display: none;
}

.kit-wrapper .condition .align-left.margin-top-20 {
    display: none;
}

.kit-wrapper .condition.nested .align-left.margin-top-20 {
    display: block !important;
}

.kit-wrapper .condition input[type="text"] {
    width: calc(100% - 20px) !important;
}

.kit-wrapper .condition span.code-icon {
    display: inline-block;
    width: 17px;
    text-align: center;
    position: relative;
    top: 3px;
    cursor: pointer;
    color: #333;
    left: 3px;
}

.kit-wrapper .ruled-ui .condition + .condition {
    border-top: 1px solid #ccc;
    padding-top: 9px;
    margin-top: 20px;
}

.hide {
    display: none !important;
}

.kit-wrapper .ruled-ui .condition label {
    display: none !important;
}

.kit-wrapper .condition-header {
    position: relative;
}

.dropdown.upward.multiple.active.visible {
    padding-top: 10px !important;
}


.icw-admin-main-container .ui.form .field .ui.dropdown span.text {
    font-size: 0px !important;
    padding: 0px !important;
    line-height: 0px;
}

.kit-wrapper .ui.form .field .ui.dropdown.mini.dark.right {
    padding: 0px !important;
    float: right;
    position: relative;
    background: transparent;
}

.kit-wrapper .ui.selection.active.dropdown.mini.dark.right .menu {
    box-shadow: 0px 5px 6px 1px rgb(115 138 173 / 31%) !important;
}


.kit-wrapper .ui.selection.active.dropdown.upward.mini.dark.right .menu {
    box-shadow: 0px -5px 6px 1px rgb(115 138 173 / 31%) !important;
}


.kit-wrapper .selection.ui.dropdown.crud {
    max-width: calc(100% - 30px);
    margin-right: 6px;
}

.kit-wrapper i.edit.icon {
    cursor: pointer;
}


.kit-wrapper .ui.form .field .ui.dropdown.mini.dark.right .menu {
    left: auto;
    right: 0px;
    background: #363652;
    background: #363652;
    top: 19px;
    border-top-right-radius: 0px !important;
}

.kit-wrapper .ui.form .field .ui.dropdown.mini.dark.right .menu .item {
    color: #e0dddd;
    font-size: 12px;
    padding-left: 5px !important;
    padding-right: 12px !important;
    padding-bottom: 0px !important;
    padding-top: 2px !important;
}

.kit-wrapper .ui.form .field .ui.dropdown.mini.dark.right::after {
    content: '';
    width: 20px;
    height: 20px;
    background: #ff000000;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1;
}


.kit-wrapper .ui.form .field .ui.dropdown.mini.dark.right span.text .filter-billing-icons img {
    margin-right: 0px;
    vertical-align: unset;
    position: relative;
    width: 25px;
    height: 23px;
}

.kit-wrapper .ui.floating.dropdown.icon.button.mini.dark.right.active.visible {
    background: #363652;
    border-radius: 0px !important;
    border-top-right-radius: 4px !important;
    border-top-left-radius: 4px !important;

}

.kit-wrapper .ui.form .field .ui.dropdown.mini.dark.right .menu .item:first-child {
    padding-top: 10px !important;
}

.kit-wrapper .ui.form .field .ui.dropdown.mini.dark.right .menu .item:last-child {
    padding-bottom: 10px !important;
}

.kit-wrapper .ui.form .field input[type="text"] {
    border: 1px solid #333333 !important;
    border-radius: 0px !important;
    height: 32px;
    padding-right: 30px;
}

.left-link {
    float: left;
    color: #007EB0;
    font-size: 12px;
    padding-top: 2px;
    cursor: pointer;
}

.align-right {
    text-align: right;
}

.float-right {
    float: right;
}

.float-left {
    float: left;
}


.form-footer {
    margin-top: 20px;
}

.error-text {
    color: #F44336;
    font-size: 12px;
    padding-top: 4px;
}

.send-password-link-wrapper, .password-reset-success-wrapper {
    text-align: center;
}

.forgot-password-wrapper .hint-text {
    margin: 10px 0px !important;
}

.icw-common-admin-header-wrapper {
    margin-top: 60px;
    background: #F7F7F7 0% 0% no-repeat padding-box;
    position: fixed;
    z-index: 1;
    width: 100%;
}


.kit-wrapper .align-right {
    text-align: right;
}


.kit-wrapper .align-left {
    text-align: left;
}


h4.secondary-header {
    width: 250px;
    float: left;
    padding-top: 6px;
    color: #007EB0;
    font-size: 18px !important;
    font-weight: 400 !important;
}

.icw-common-admin-details-wrapper {
    padding-top: 130px;
    padding-bottom: 100px;
}

.admin-details-common-div-wrapper {
    max-width: 630px;
    margin: auto;
}

.equal-columns-2 h5.ui.header {
    margin-bottom: 10px;
    font-size: 14px;
    font-family: 'Roboto';
    font-weight: 600;
    width: 100%;
}

.equal-columns-2 .field > label {
    padding-top: 5px;
    display: inline-block;
    max-width: 250px !important;
    float: left;
    width: 250px !important;
}

.equal-columns-2 .field > label + p {
    padding-top: 5px;
    display: inline-block;
    width: 250px;
    font-weight: 400 !important;
}


.equal-columns-2 .field > label + input[type="file"] {
    border: none;
    display: inline-block;
    width: 140px;
    cursor: pointer;
    padding-left: 0px;
}

.secure-file-share-landing-wrapper .icw-common-box-wrapper, .sent-sfs-external-user .icw-common-box-wrapper {
    text-align: center;
    width: 300px;
    margin-left: -140px !important;
}

.sfs-common-text {
    color: #888888;
}

button.ui.primary.button.left-btn {
    float: left;
}

.marT-20 {
    margin-top: 20px !important;
}

.external-user-file-upload-wrapper {
    background: #F8F8F8 0% 0% no-repeat padding-box;
    border: 1px dashed #B7B7B7;
    border-radius: 4px;
    height: 140px;
    margin: 10px 0px;
}

.choose-file-upload input[type="file"] {
    background: transparent !important;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -83px !important;
    max-width: 180px;
    border: none !important;
    margin-top: 12px !important;
    cursor: pointer;
    padding-left: 0px !important;
}


.file-upload-preview-wrapper {
    padding: 14px;
}

.file-upload-preview-wrapper h4 {
    font-size: 13px;
    font-weight: 400 !important;
}

.file-name {
    overflow: hidden;
    max-width: calc(100% - 80px);
    white-space: nowrap;
    text-overflow: ellipsis;
}

.uploaded-file-wrapper span {
    float: left;
    font-size: 12px !important;
    color: #333333;
}

.check-icon {
    margin-right: 4px;
}

.file-size {
    margin-left: 8px;
    margin-right: 8px;
}

.uploaded-file-wrapper {
    border-top: 0.5px solid #B7B7B7;
    overflow: hidden;
    border-bottom: 0.5px solid #B7B7B7;
}

.file-size, .delete-file {
    float: right !important;
}

.add-more-files {
    cursor: pointer;
    font-size: 12px;
    padding-top: 6px;
    clear: both;
    display: block;
}

.delete-file img {
    cursor: pointer;
}

.external-user-form-details-wrapper .icw-common-box-wrapper {
    margin-top: 100px !important;
}

.request-file-email-details-wrapper {
    padding: 10px;
}

.choose-file-wrapper {
    position: relative;
}

.choose-file-wrapper input[type="file"] {
    left: 0px;
    max-width: 80px;
    border: none !important;
    z-index: 1;
    padding: 0px !important;
    overflow: hidden;
    opacity: 0;
    position: absolute;
    top: 0px;
    cursor: pointer !important;
}

span.choose-file {
    background: #fff;
    width: 80px;
    cursor: pointer !important;
    color: #007eb0;
}

.request-file-email-details-wrapper p {
    margin-bottom: 0px;
    padding-left: 10px !important;
    font-size: 12px !important;
    padding-right: 10px;
}

.request-file-details-wrapper .sfs-common-text {
    margin-bottom: 0px !important;
}


.secure-file-share-landing-wrapper h5 {
    text-align: center;
    margin: 40px 0px 40px;
    font-weight: 400 !important;
}


.external-user-file-upload-wrapper .choose-file-wrapper {
    position: absolute;
    left: 50%;
    margin-left: -50px;
    top: 50%;
    margin-top: 20px !important;
    background: transparent;
}

.external-user-file-upload-wrapper span.choose-file {
    background: transparent;
}

.uploaded-file-wrapper + .uploaded-file-wrapper {
    border-top: 0px;
}

.files-uploading-wait .icw-common-box-wrapper {
}

.files-uploading-wait .ui.segment {
    height: 300px;
}

.files-uploading-wait .ui.form {
    position: absolute;
    left: 20%;
    top: 33%;
}


.sign-in-wrapper h4.ui.header {
    max-width: 200px;
    margin: auto;
}


.wide.column {
    width: 50%;
    display: table-cell;
    box-sizing: border-box;
    vertical-align: middle;
}

.wide.column .segment {
    background: transparent;
}

.app > div {
    clear: both;
    overflow: hidden;
}

.ui.row, .wide.column, .wide.column .segment {
    background: transparent;
    border: none;
    box-shadow: none;
}

.ui.row {
    display: table;
    width: 100%;
}

.body-content .kit-wrapper {
    margin: 40px auto;
}


.app {
    position: relative;
    height: 100%;
    width: 100%;
}

.equal-columns-2, .equal-columns-3, .equal-columns-4, .equal-columns-5 {
    display: table;
    width: 100%;
}

.kit-wrapper button {
    padding: 7px 20px !important;
    min-width: 80px !important;
    font-size: 13px !important;
    background: #007EB0 !important;
    font-weight: 400 !important;
}


.equal-columns-2 button {
    padding: 7px 20px !important;
    min-width: 80px !important;
    font-size: 13px !important;
    font-weight: 400 !important;

}


/* .ui.form.equal-columns-2 .field > input[type=text], .ui.form.equal-columns-2 .field > label + p, .ui.form.equal-columns-2 .field > textarea, .ui.form.equal-columns-2 .field > input[type="password"] {
    max-width: 350px !important;
    width: 350px !important;
} */

.equal-columns-2 .field > input[type=text], .equal-columns-2 .field > input[type="password"] {
    padding-right: 30px;
}

.wide.column .ui.segment {
    padding: 0px;
}

.ui.form iframe {
    height: 400px !important;
}

.equal-columns-3 .wide.column {
    display: table-cell;
    width: 33%;
    box-sizing: border-box;
    vertical-align: middle;
}

.equal-columns-4 .wide.column {
    display: table-cell;
    width: 25%;
    box-sizing: border-box;
    vertical-align: middle;
}

.equal-columns-5 .wide.column {
    display: table-cell;
    width: 20%;
    box-sizing: border-box;
    vertical-align: middle;
}

a {
    cursor: pointer;
}

.loading-view {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: -70px;
    margin-left: -20px;
    text-align: center;
}

.kit-wrapper .ui.form .field input[type="password"] {
    border: 1px solid #333 !important;
    border-radius: 0 !important;
    height: 32px;
    padding-right: 30px;
}

.kit-wrapper .ui.primary.button:focus {
    box-shadow: 0 0 0 1px transparent inset, 0 0 1px rgba(81, 167, 232, .8) inset, 0 0 3px 2px rgba(81, 167, 232, .8) !important;
}

.equal-columns-1 {
    display: table;
    width: 100%;
}

.equal-columns-1 .wide.column {
    width: 100%;
    display: table-cell;
    box-sizing: border-box;
    vertical-align: middle;
}

.kit-wrapper .ui.form .field .ui.checkbox input[type="checkbox"] {
    position: absolute;
}

.kit-wrapper .field .ui.radio.checkbox label {
    font-size: 12px !important;
    margin-right: 13px;
    padding-top: 2px;
}

.kit-wrapper .field .ui.radio.checkbox {
    padding-top: 7px;
}

.footer {
    position: fixed;
    width: 100%;
    bottom: 0;
}

.checkbox-container .ui.checkbox {
    margin-right: 10px;
}

.no-border, table.no-border, .no-border tr, .no-border td {
    border: none !important;
}

.loading-wrapper {
    position: absolute;
    left: 50%;
    margin-left: -37px;
    top: 50%;
    margin-top: -37px;
}

.loading-circle {
    width: 30px;
    height: 30px;
    border-top: 5px solid #292929;
    border-right: 5px solid #efefef;
    border-bottom: 5px solid #efefef;
    border-left: 5px solid #efefef;
    border-radius: 100px;
    -webkit-animation: spin 0.9s infinite linear;
    animation: spin 0.9s infinite linear;
}

@keyframes spin {
    100% {
        transform: rotate(360deg);
    }
}

.loading-text {
    font-size: 15px;
    margin-left: -11px;
    position: relative;
    top: 6px;
}

.loading-wrapper.loading-inline {
    position: relative;
    display: inline-block;
    left: auto;
    zoom: 0.5;
    top: 6px;
    margin: auto auto auto 8px;
}

.loading-wrapper.loading-inline .loading-circle {
    border: 5px solid #c1c0c0;
    border-top-color: #292929;
}

a.disabled {
    color: grey;
    cursor: default;
}

.ui.segment {
    border-radius: 0;
}